<template>
  <main class="basicinfo">
    <div style="text-align: left;"><Head title="基本信息"></Head></div>
    <div v-if="!isEdit" class="cont">
      <el-row>
        <el-col :span="8"><div class="l-title">营业执照图片：</div></el-col>
        <el-col :span="16">
          <div style="text-align: left;">
            <img class="avatar"   :src="info.businessLicense" style="width: 80px; height:80px;vertical-align: middle;" />
          </div>
        </el-col>

        <!-- <el-col :span="8"><div class="l-title">营业执照图片：</div></el-col>
        <el-col :span="16">
          <div class="r-cont">
            <el-image
                class="avatar"
              :src="info.businessLicense"
              :preview-src-list="[info.businessLicense]"
              fit="cover"></el-image>
          </div></el-col> -->
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">企业名称：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.enterpriseName}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">统一社会信用代码：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.creditCode}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">企业法人：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.corporationName}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">企业地址：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.enterpriseAddress}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">企业联系电话：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.contactNumber}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">姓名：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.name}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">性别：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{ info.gender }}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">身份证号：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.idCard}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">昵称：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.nickName}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">联系地址：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.address}}</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">邮箱：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.email}}</div></el-col>
      </el-row>
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">联系人电话：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.phone}}</div></el-col>
      </el-row> -->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">企业通讯地址：</div></el-col>
        <el-col :span="16"><div class="r-cont">天山区西河坝前街111号</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">开户行信息：</div></el-col>
        <el-col :span="16"><div class="r-cont">老李</div></el-col>
      </el-row>-->
      <!-- <el-row>
        <el-col :span="8"><div class="l-title">职业：</div></el-col>
        <el-col :span="16"><div class="r-cont">{{info.vocation}}</div></el-col>
      </el-row> -->
      <!--<el-row>
        <el-col :span="8"><div class="l-title">税号：</div></el-col>
        <el-col :span="16"><div class="r-cont">123891461</div></el-col>
      </el-row>
      <el-row>
        <el-col :span="8"><div class="l-title">电子邮箱：</div></el-col>
        <el-col :span="16"><div class="r-cont">14141312@163.com</div></el-col>
      </el-row> -->


      <div class="btn-wrap">
        <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
        <el-button
          type="primary"
          style="width: 120px; margin-right: 50px"
          @click="btnEdit"
          >编辑</el-button
        >
      </div>
    </div>
    <!-- 编辑 -->
    <div v-else class="cont">
      <el-form
        ref="ruleFormRules"
        v-loading="fromloding"
        :model="ruleForm"
        :rules="ruleFormRules"
        label-width="150px"
        class="demo-ruleForm"
        :validate-on-rule-change="true"
      >
        <el-form-item prop="imgUrl" label="营业执照图片：">
          <div>
            <!--:type="2"-->
            <Image-upload
              :file-arr="ruleForm.img_url"
              @getUploadList="getUploadList"
            />
            <p class="img-tip">
              支持JPG\JPEG\PNG格式，不超过5M！
            </p>
          </div>
        </el-form-item>
        <el-form-item label="企业名称：" prop="enterpriseName">
          <el-input
            v-model="ruleForm.enterpriseName"
            clearable
            type="text"
            placeholder="请输入您要修改的企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码：" prop="creditCode">
          <el-input
            v-model="ruleForm.creditCode"
            clearable
            type="text"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业法人：">
          <el-input
            v-model="ruleForm.corporationName"
            clearable
            type="text"
            placeholder="请输入企业法人"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业地址：">
          <el-input
            v-model="ruleForm.enterpriseAddress"
            clearable
            type="text"
            placeholder="请输入企业地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业联系电话：">
          <el-input
            v-model="ruleForm.contactNumber"
            clearable
            type="text"
            placeholder="请输入企业联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input
            v-model="ruleForm.name"
            clearable
            type="text"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <div style="float:left">
            <el-radio-group v-model="ruleForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="身份证：" prop="idCard">
          <el-input
            v-model="ruleForm.idCard"
            clearable
            type="text"
            placeholder="请输入身份证号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
          <el-input
            v-model="ruleForm.nickName"
            clearable
            type="text"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系地址：">
          <el-input
            v-model="ruleForm.address"
            clearable
            type="text"
            placeholder="请输入联系地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱：" prop="email">
          <el-input
            v-model="ruleForm.email"
            clearable
            type="text"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="联系电话：">
          <el-input
            v-model="ruleForm.phone"
            clearable
            type="text"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        
        <el-form-item label="职业：">
          <el-input
            v-model="ruleForm.vocation"
            clearable
            type="text"
            placeholder="请输入职业"
          ></el-input>
        </el-form-item>
        <el-form-item label="职称：" >
          <el-input
            v-model="ruleForm.vocationTitle"
            clearable
            type="text"
            placeholder="请输入职称，多个请使用,分隔"
          ></el-input>
        </el-form-item> -->
        <div class="btn-wrap">
          <!-- <el-button style="width: 120px" @click="cancel">取消</el-button> -->
          <el-button
            type="primary"
            style="width: 120px; margin-right: 50px"
            @click="btnSave"
            >保存</el-button
          >
        </div>
      </el-form>
    </div>
  </main>
</template>

<script>
import { getUserDetail,postUserDetail } from '@/api/common'
import ImageUpload from '@/components/Upload/ImageUpload'
import {validEmail, isId15, isId18 } from '../../../utils/validate'
import Head from './Head'
export default {
  name: 'Entbasicinfo',
  components: {
    ImageUpload,
    Head
  },
  data() {
    return {
      // payImage: '',

      info:{}, //存储接口返回的信息
      loading: false,
      fromloding: false,
      isEdit: false, // 标记是否编辑状态
      ruleForm: {
        enterpriseName:"",//企业名称
        creditCode:"",//信用代码
        enterpriseAddress:'',
        contactNumber:'',
        corporationName: '',
        idCard: '',
        email: '',
        name:"",//联系人
        nickName: '', // 昵称
        address: '', // 学员所在地
        phone:"",//联系人电话
        gender: 3, // 默认值
        img_url: [],
        vocation: '', // 职业
        vocationTitle: '' //职称
      },
      ruleFormRules: {
        enterpriseName:[
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        creditCode:[
          { required: true, message: '信用代码不能为空', trigger: 'blur' }
        ],
        name:[
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        vocation:[
          { required: true, message: '联系人电话不能为空', trigger: 'blur' }
        ]
      },
    }
  },
  mounted() {
    this.getBasicInfo()
  },
  methods: {
    //获取企业基本信息数据
    getBasicInfo() {
      getUserDetail().then((res)=>{
        console.log(res)
        if(res.status === 200){
          if(res.data.code ===0){
              this.info = res.data.data
              this.info.gender = res.data.data.gender === 3 ? '未知' :  (res.data.data.gender=== 1 ? '男' : '女') 
          }
        }else{
          this.$message({
            type:'error',
            message:res.data.message
          })
        }
      })
    },
    // 编辑
    btnEdit() {
      console.log("edit....");
      this.isEdit= true
      getUserDetail().then((res)=>{
        console.log(res)
        if(res.status === 200){
          if(res.data.code ===0){
              this.ruleForm.enterpriseName = res.data.data.enterpriseName
              this.ruleForm.creditCode = res.data.data.creditCode
              this.ruleForm.corporationName = res.data.data.corporationName
              this.ruleForm.enterpriseAddress = res.data.data.enterpriseAddress
              this.ruleForm.contactNumber = res.data.data.contactNumber



              this.ruleForm.img_url = [res.data.data.businessLicense]
              this.ruleForm.name = res.data.data.name
              this.ruleForm.nickName = res.data.data.nickName
              this.ruleForm.gender = res.data.data.gender
              this.ruleForm.idCard = res.data.data.idCard
              this.ruleForm.address = res.data.data.address
              this.ruleForm.email = res.data.data.email
              // this.ruleForm.vocation = res.data.data.vocation
              // this.ruleForm.vocationTitle = res.data.data.vocationTitle
              // this.ruleForm.phone = res.data.data.phone

              console.log(this.ruleForm);
          }
        }else{
          this.$message({
            type:'error',
            message:res.data.message
          })
        }
      })
    },
    // 保存
    btnSave() {
      if(this.ruleForm.email) {
        // console.log(this.ruleForm.email);
          // console.log(validEmail(this.ruleForm.email));
        if(!validEmail(this.ruleForm.email)) {
          this.$message({
            type: 'error',
            message: '请输入正确邮箱！'
          })
          return
        }
      }
      if(this.ruleForm.idCard){
        if(isId15(this.ruleForm.idCard) || isId18(this.ruleForm.idCard)) {
          this.$message({
            type: 'error',
            message: '请输入正确身份证号码！'
          })
          return
        }
      }
      this.$refs.ruleFormRules.validate((vaild) => {
        if (vaild) {
          console.log('pass...');
          const { enterpriseName, creditCode,corporationName, enterpriseAddress,contactNumber, name, nickName,address, idCard,email,gender, vocation, vocationTitle, img_url} = this.ruleForm
          const obj = {
            enterpriseName, 
            creditCode, 
            corporationName,
            enterpriseAddress,
            contactNumber,
            name,
            nickName,
            address,
            idCard,
            email,
            gender,
            vocation, 
            vocationTitle,
            businessLicense: img_url[0]
          }
          postUserDetail(obj).then(res=>{
            if(res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '修改成功！',
                onClose: ()=>{
                  this.isEdit = false
                  this.getBasicInfo()
                }
              })
            }else{
              this.$message({
                type: 'error',
                message: res.data.message,
                onClose: ()=>{

                }
              })
            }
          })
        }
      })
    },
    getUploadList(val) {
      console.log(val, 'getUploadList');
      this.ruleForm.img_url = val
      // this.payImage = this.ruleForm.img_url
      
    },
  }
}
</script>

<style lang="scss" scoped>
 .basicinfo {
  em {
    display: flex;
    font-size: 18px;
  }
  .cont {
    width: 80%;
    margin: 30px auto 0;
    .l-title {
      margin: 20px 0;
      text-align: right;
      color: #666;
    }
    .r-cont {
      color: #333;
      margin: 20px 0;
      text-align: left;
      .avatar{
        width: 120px;
        height: 120px;
        border-radius: 80px;
        margin-top: -60px;
      }
    }
    .btn-wrap {
      margin-top: 20px;
    }
    .demo-ruleForm{
      width: 550px;
    }
  }
 }
</style>